import { ErrorAlert, useDialog } from '@ampeersenergy/ampeers-ui-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DeepExtractType } from 'ts-deep-extract-types';

import { useHasRole } from '../../../../components/useHasRole';
import {
  ReadContractDocument,
  ReadContractQuery,
  useGenerateContractDocumentMutation,
  useGenerateContractDocumentPreviewMutation,
  useRemoveDocumentsMutation,
} from '../../../../graphql-types';
import {
  documentTypeGroups,
  documentTypesI18nToKey,
  documentTypesKeyToI18n,
  translateDocumentType,
} from '../../../../helpers/formatStrings';
import { DocumentsSidebar } from '../../../../components/documentsSidebar';
import { buildDocumentUrl } from '../../../../helpers/buildDocumentUrl';
import type {
  ContractDocument,
  DocumentType,
} from '../../../../components/documents';

type ContractDocumentData = DeepExtractType<
  ReadContractQuery,
  ['readContract', 'documents']
>;

export function getDocumentType(document: ContractDocumentData[0]) {
  const { type, fileName, userUpload } = document;

  // Logic for handling old invoices created by Odoo
  if (!userUpload && type === 'accounting') {
    if (fileName?.includes('STORNIERT')) {
      return 'cancelled_invoice';
    }
    if (fileName?.includes('Reversal')) {
      return 'reversal_invoice';
    }
  }
  return type;
}

function getDocumentGroup(document: ContractDocumentData[0]) {
  return documentTypeGroups.find((g) =>
    g.types.includes(getDocumentType(document)),
  )?.name;
}

export function createDocumentLabel(document: ContractDocumentData[0]) {
  const { type, fileName, userUpload } = document;

  // if userUpload is false and type is accounting the document was created in Odoo
  if (!userUpload && type === 'accounting') {
    if (fileName?.includes('OPC')) {
      return fileName.split('-').pop()?.replace('.pdf', '') ?? 'OPC';
    }
  }

  return translateDocumentType(type);
}

type DocumentGroup = Record<
  string,
  { documents: ContractDocument[]; type: string }
>;

export function ContractSidebar({
  documents,
  contractStatus,
  customerEmail,
  contractId,
  documentDeliveryMethod,
  refetch,
}: {
  documents?: ContractDocumentData;
  contractStatus?: string | null;
  customerEmail?: string | null;
  contractId?: string;
  documentDeliveryMethod: string;
  refetch: () => void;
}) {
  const [generateContractDocumentMutate] =
    useGenerateContractDocumentMutation();

  const { openDialog } = useDialog();
  const [removeDocumentsMutation] = useRemoveDocumentsMutation();

  const [previewMutation] = useGenerateContractDocumentPreviewMutation();

  const [documentInitiallyShown, setDocumentInitiallyShown] = useState<
    null | string
  >();

  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const navigate = useNavigate();

  const canCreateWelcomeLetter = useHasRole('feature_welcome_letter');
  const canCreateContractConfirmationLetter = useHasRole(
    'feature_contract_confirmation_letter',
  );
  const previewMutate = useCallback(
    async (type: any, _contractId: any) => {
      return previewMutation({
        variables: {
          contractId: _contractId,
          type,
        },
      });
    },
    [previewMutation],
  );

  const generateDocumentMutate = useCallback(
    async (type: any, _contractId: any, deliveryPreference: any) => {
      return generateContractDocumentMutate({
        variables: {
          contractId: _contractId,
          type,
          deliveryPreference,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: ReadContractDocument,
            variables: {
              contractId: _contractId,
            },
          },
        ],
      });
    },
    [generateContractDocumentMutate],
  );

  const defaultDocuments = useMemo(() => {
    const welcomeLetterDefault: DocumentGroup = {
      [documentTypesKeyToI18n.welcome_letter]: {
        documents: [],
        type: 'welcome_letter',
      },
    };

    const contractDocumentDefault: DocumentGroup = {
      [documentTypesKeyToI18n.contract_confirmation_letter]: {
        documents: [],
        type: 'contract_confirmation_letter',
      },
    };

    return {
      ...(canCreateWelcomeLetter.hasRole && welcomeLetterDefault),
      ...(canCreateContractConfirmationLetter.hasRole &&
        contractDocumentDefault),
    };
  }, [canCreateWelcomeLetter, canCreateContractConfirmationLetter]);

  useEffect(() => {
    const documentToCreate = queryParams.get('createDocument');
    if (!documentToCreate) return;

    const documentName = translateDocumentType(documentToCreate);

    if (
      Object.keys(defaultDocuments).includes(documentName) &&
      customerEmail &&
      contractId &&
      documentDeliveryMethod
    ) {
      if (
        documentToCreate === 'contract_confirmation_letter' &&
        !contractStatus?.endsWith('_ACTIVE')
      ) {
        return;
      }
      queryParams.delete('createDocument');
      navigate({
        pathname: location.pathname,
        search: queryParams.toString(),
      });
      setDocumentInitiallyShown(documentName);
    }
  }, [
    queryParams,
    customerEmail,
    contractId,
    documentDeliveryMethod,
    defaultDocuments,
    contractStatus,
    location.pathname,
  ]);

  const deleteDocument = useCallback(
    async (document: ContractDocument) => {
      if (document.hash) {
        try {
          await removeDocumentsMutation({
            variables: { hash: document.hash },
            refetchQueries: [
              {
                query: ReadContractDocument,
                variables: {
                  contractId,
                },
              },
            ],
          });
        } catch (error) {
          openDialog({
            title: 'Fehler',
            content: (
              <ErrorAlert>
                Beim Löschen des Dokuments ist leider ein Fehler aufgetreten.
                Bitte versuche es erneut oder wende Dich an den Service Desk.
              </ErrorAlert>
            ),
          });
        }
      }
    },
    [contractId, removeDocumentsMutation, openDialog],
  );

  const byType = useMemo(() => {
    if (!documents) {
      return [];
    }
    const groupByType = documents.reduce(
      (acc, d) => {
        const { fileName, fileURL, ...doc } = d;

        const isInvoiceXMLDocument =
          d.type.includes('invoice') && d.contentType === 'application/xml';
        if (isInvoiceXMLDocument) {
          return acc;
        }

        const group = getDocumentGroup(d);
        const label = createDocumentLabel(d);
        const key = group ?? label;

        const deletable =
          !['accounting', 'invoice'].includes(doc.type) || !!doc.userUpload;

        const findAssociatedXML = documents.find(
          (_d) =>
            _d.type === 'invoice' &&
            _d.meta?.opcName === doc.meta?.opcName &&
            _d.contentType === 'application/xml',
        );

        const document: ContractDocument = {
          ...doc,
          type: label,
          internalType: d.type,
          fileURL: buildDocumentUrl(fileURL),
          ...(fileName !== null && { fileName }),
          showFileName: !!fileName && doc.type === 'invoice',
          deletable,
          xml: findAssociatedXML?.fileURL
            ? buildDocumentUrl(findAssociatedXML.fileURL)
            : undefined,
        };

        return {
          ...acc,
          [key]: {
            documents: [
              ...(Object.prototype.hasOwnProperty.call(acc, key)
                ? acc[key].documents
                : []),
              document,
            ],
            type: d.type,
          },
        };
      },
      { ...(defaultDocuments as DocumentGroup) },
    );

    const byTypeArr: DocumentType[] = [];

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in groupByType) {
      const enableCreation =
        documentTypesI18nToKey[key] === 'contract_confirmation_letter' &&
        !contractStatus?.endsWith('_ACTIVE');

      byTypeArr.push({
        type: key,
        groupAlways: ['accounting', 'invoice'].includes(groupByType[key].type),
        ...(groupByType[key].documents.length === 0 &&
          enableCreation && {
            creation: {
              disabled: true,
              hint: 'Um eine Lieferbestätigung zu erstellen muss der Vertrag aktiv sein',
            },
          }),
        ...(groupByType[key].documents.length > 0 && {
          documents: groupByType[key].documents,
        }),
      });
    }

    return byTypeArr;
  }, [defaultDocuments, contractStatus, documents]);

  return (
    <DocumentsSidebar
      documentTypes={byType}
      customerEmail={customerEmail!}
      contractId={contractId!}
      documentDeliveryMethod={documentDeliveryMethod}
      documentInitiallyShown={documentInitiallyShown}
      previewDocumentMutation={previewMutate}
      generateDocumentMutation={generateDocumentMutate}
      allowUpload
      refetchDocuments={refetch}
      onDelete={deleteDocument}
    />
  );
}
